import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ImagesData from "../../components/images"

const SolarNiranjanPage = () => (
  <Layout>
    <SEO title="Solar Niranjan" />
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-12 flex-center justify-center position-ref font-weight-bold">
          <h3 className="agney-rainbow"><b>Solar Niranjan</b></h3>
        </div>
      </div> <br/>
      <div className="row">
        <div className="col-xs-3 col-md-3">
          <ImagesData imgName="niranjan_1" className="img-thumbnail img-responsive w-100 center-block" width="100%" alt="Agneya Products" title="Agneya Special Solar Products"/>
          <div>Solar Niranjan</div>
        </div>
        <div className="col-xs-3 col-md-3">
          <ImagesData imgName="niranjan_2" className="img-thumbnail img-responsive w-100 center-block" width="100%" alt="Agneya Products" title="Agneya Special Solar Products"/>
          <div>Solar Niranjan (Dual charging)</div>
        </div>
        <div className="col-xs-6 col-md-6">
          <ol>
            <li><b className="font-weight-bold">Specifications</b> :
              <ul>
                <li>Niranjan metallic with silver plating.</li>
                <li>Height- 5cm (approx)</li>
                <li>Base ABS material (7cm*7cm*3.5cm)</li>
                <li>Single led light</li>
                <li>Solar charging : Min 4-6 hrs (good sunlight)</li>
              </ul>
            </li>
            <li>
<b className="font-weight-bold">Utilization</b> : 10 - 12 hrs. (when fully charged)
            </li>
            <li>
<b className="font-weight-bold">Additional feature (Optional)</b> : Solar + USB Dual charging (Solar + Electric)
- can be charged with mobile charger incase of low sunlight
            </li>
            <li>
<b className="font-weight-bold">MRP</b> : 
              <ul>
                <li> Dual charging type - ₹ 1000/-</li>
                <li> Only Solar Charching type - ₹ 850/-</li>
              </ul>
            </li>
          </ol>
        </div>
      </div> <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
          <h4><b>User Guide</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
The box will act as a solar charger of your Niranjan. <b className="font-weight-bold">Before 1st use, keep the box for charging in the sunlight for atleast one day. </b>

For charging keep the box bit tilted (facing solar panel towards sunlight) at place where it can get maximum sunlight during the day.

Once its charged, then match the two signs on the box with the signs on the bottom of the Niranjan & press it. And its done. Your Niranjan is lighten up and ready to use.

To switch off the Niranjan, rotate it in the direction of the arrow.
You can use colgate powder to clean the Niranjan. Don’t use water.
        </div>
      </div> <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
          <h4><b>Precautions</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
Every morning, detach the box from Niranjan and keep it for charging. A simple pull will separate the Niranjan from the box.
<b className="font-weight-bold"> Please be careful that the buttons on the box are not touching any metal surface while charging. </b>

If Niranjan gets deem, detach it from the box. Please don’t use the Niranjan till box gets charged sufficiently.

If you find that there is low sunlight, don't worry. Keep it for charging. It will still get charged but it will require more time than usual.

If Niranjan is not in use, then it should  be kept separate from the box. Don’t keep the box in cupboard. Keep the box in the light (facing solar panel towards sunlight). It will prevent self discharging.
        </div>
      </div> <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
      For Solar Niranjan with USB :
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
If there is no sunlight, mobile charger can be used for charging. It is SOLAR NIRANJAN, so please use mobile charger only in the absence of sunlight. Overcharging through mobile charger, may damage the battery. If any doubt, feel free to contact.
        </div>
      </div> <br/>
    </div>
  </Layout>
)

export default SolarNiranjanPage
